import { Skeleton, Stack } from '@lualtek/react-components';

export const SkeletonMessages = () => (
  <>
    <Stack hPadding={24} vPadding={8} hAlign="end">
      <Skeleton height={40} radius={16} width="35ch" />
    </Stack>
    <Stack hPadding={24} vPadding={8} hAlign="start" fill={false}>
      <Skeleton height={40} radius={16} width="30ch" />
    </Stack>
    <Stack hPadding={24} vPadding={8} hAlign="end">
      <Skeleton height={40} radius={16} width="30ch" />
    </Stack>
    <Stack hPadding={24} vPadding={8} hAlign="end">
      <Skeleton height={40} radius={16} width="40ch" />
    </Stack>
  </>
);
