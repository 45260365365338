import {
  Button, Sheet, SheetProps, Stack, Text,
} from '@lualtek/react-components';

import { useTranslate } from '@/core/i18n';

export type SharedDeleteModalProps = {
  trigger?: SheetProps['trigger'];
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
  loading?: boolean;
}

type DeleteModalProps = SharedDeleteModalProps & {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
}

export const DeleteModal: FCClass<DeleteModalProps> = ({
  isOpen,
  onDismiss,
  onDelete,
  title,
  subtitle,
  content,
  trigger,
  loading,
}) => {
  const { t } = useTranslate();
  return (
    <Sheet
      open={isOpen}
      heading={title}
      onClose={onDismiss}
      description={subtitle}
      trigger={trigger}
    >
      <Stack hPadding={24} rowGap={40}>
        <Text align="center">
          {content}
        </Text>

        <Stack direction="row" columnGap={8} hAlign="center" fill={false}>
          <Button
            kind="flat"
            onClick={onDismiss}
            disabled={loading}
          >
            {t('common:cta.cancel')}
          </Button>
          <Button
            onClick={onDelete}
            sentiment="danger"
            disabled={loading}
            busy={loading}
            data-testid="delete-confirm"
          >
            {t('common:cta.confirm')}
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );
};
