import { useCallback } from 'react';

import { DeleteModal } from '@/components/common/shared/delete-modal';
import { SharedDeleteModalProps } from '@/components/common/shared/delete-modal/delete-modal';
import { Trans, useTranslate } from '@/core/i18n';

  type DeleteChatModalProps = Omit<SharedDeleteModalProps, 'onDelete'> & {
    onDelete: () => Promise<void>;
  }

export const DeleteChatModal = ({
  isOpen,
  onDismiss,
  onDelete,
  loading,
}: DeleteChatModalProps) => {
  const { t } = useTranslate();

  const handleDelete = useCallback(async () => {
    await onDelete();
    onDismiss();
  }, [onDelete, onDismiss]);

  return (
    <DeleteModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      onDelete={handleDelete}
      title={t('luma:modal.delete.title')}
      subtitle={t('luma:modal.delete.subtitle')}
      loading={loading}
      content={(
        <Trans
          i18nKey="luma:modal.delete.content"
          components={[<strong />]}
        />
      )}
    />
  );
};
