import { Message } from 'ai';

import { ChatMessageDataFragment } from '@/generated/graphql';

export const mapChatMessages = (messages: ChatMessageDataFragment[]): Message[] => messages.slice().reverse().map(({
  id, isUserMessage, content, createdAt,
}) => ({
  id: String(id),
  role: isUserMessage ? 'user' : 'assistant',
  content,
  createdAt: new Date(createdAt),
}));

interface TokensInfo {
  maxTokensChatMonth: number;
  tokensMonthUsed: number;
}

// TYPICAL_REQUEST_TOKENS assumes:
// - 2,000 tokens for user input
// - 2,000 tokens for the assistant response
// -------------------------------------------------------
// Since 1 token ≈ 4 chars, 2,000 tokens ≈ 8,000 chars of user input.
// -------------------------------------------------------
// Total per request: 4,000 tokens
const TYPICAL_REQUEST_TOKENS = 4000;
const REQUEST_THRESHOLD = 20; // Warn when fewer than 20 requests remain

/**
 * Calculates the percentage of tokens used and determines if we should alert the user.
 * - If maxTokensChatMonth is 0, consider the limit fully reached (100%).
 * - Otherwise, warn if remaining tokens < (REQUEST_THRESHOLD * TYPICAL_REQUEST_TOKENS).
 *
 * @param params - Contains maxTokensChatMonth (monthly token limit) and tokensMonthUsed (tokens used so far).
 * @returns An object with:
 *  - percentageUsed: number (0 to 100)
 *  - shouldAlert: boolean indicating if the user should be warned
 */
export const calculateTokensUsage = (params: TokensInfo): { percentageUsed: number; shouldAlert: boolean } => {
  const { maxTokensChatMonth, tokensMonthUsed } = params;

  if (maxTokensChatMonth === 0) {
    // Feature disabled => fully reached
    return { percentageUsed: 100, shouldAlert: true };
  }

  const percentageUsed = (tokensMonthUsed / maxTokensChatMonth) * 100;
  const remainingTokens = maxTokensChatMonth - tokensMonthUsed;

  const alertThresholdTokens = REQUEST_THRESHOLD * TYPICAL_REQUEST_TOKENS;
  const shouldAlert = remainingTokens <= alertThresholdTokens;

  return {
    percentageUsed: Number(Math.min(Math.max(percentageUsed, 0), 100).toFixed(0)),
    shouldAlert,
  };
};
