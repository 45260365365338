import {
  Sheet,
  Stack,
} from '@lualtek/react-components';

import { NavLink } from '@/components/common/mobile/navlink';
import { Luma } from '@/components/luma/mobile/chat';
import {
  LumaLogo,
} from '@/components/luma/shared';
import { useLumaContext } from '@/context/use-luma-context';

import { useLumaWrapperLogic } from '../../shared/use-luma-wrapper-logic';
import styles from './luma-wrapper.module.css';

export type LumaWrapperProps = {
  loading?: boolean;
}
export const LumaWrapper: FCChildren<LumaWrapperProps> = () => {
  const { isLumaOpened, setIsLumaOpened } = useLumaContext();
  const { lumaProps } = useLumaWrapperLogic();

  return (
    <Sheet
      open={isLumaOpened}
      shouldScaleBackground
      repositionInputs={false}
      fixed
      dismissible
      trigger={(
        <NavLink
          icon="ai-chat"
        />
      )}
      onOpenChange={open => setIsLumaOpened(open)}
      direction="bottom"
      compactHeader
      heading={<LumaLogo dimension="regular" />}
      maxWidth="700px"
      noPadding
      showHeading={false}
    >
      <Stack className={styles.ChatPanel}>
        <Luma {...lumaProps} />
      </Stack>
    </Sheet>
  );
};

