import { useCallback, useMemo, useRef } from 'react';

import { useTranslate } from '@/core/i18n';
import {
  Locale,
  NotificationsEventsDocument,
  NotificationsMessagesDocument,
  NotificationsNotReadCountByTypeDocument,
  NotificationsNotReadCountDocument,
  NotificationsReleasesDocument,
  useNotificationMessageContentQuery,
  useNotificationReleaseContentQuery,
  useNotificationsAllReadMutation,
  useNotificationsEventsQuery,
  useNotificationsMessagesQuery,
  useNotificationsNotReadCountByTypeQuery,
  useNotificationsNotReadCountQuery,
  useNotificationsReadMutation,
  useNotificationsReleasesQuery,
  useNotificationsSettingsQuery,
  useNotificationsSettingsUpdateMutation,
} from '@/generated/graphql';
import { extractError } from '@/services/apollo/extract-error';

import { HookProps } from '../types';

export const useNotificationsEvents = (props?: HookProps) => {
  const {
    data,
    loading,
    error,
    fetchMore,
  } = useNotificationsEventsQuery({
    onError: error => props?.onError?.(extractError(error)),
    variables: {
      offset: 0,
    },
  });

  const fetchMoreCount = useRef(-1);
  const eventsCount = useMemo(() => data?.notificationsEvents?.length ?? 0, [data?.notificationsEvents?.length]);
  const hasMoreEvents = useMemo(() => {
    const events = data?.notificationsEvents ?? [];
    const totalCount = fetchMoreCount.current + eventsCount;
    return events.length === totalCount;
  }, [data?.notificationsEvents, eventsCount]);

  const fetchMoreEvents = useCallback(
    async (props?: { offset?: number }) => {
      if (fetchMoreCount.current === 0) {
        return;
      }

      const { data: fetchMoreData } = await fetchMore({
        variables: {
          offset: props?.offset ?? data?.notificationsEvents?.length ?? 0,
        },
      });
      fetchMoreCount.current = fetchMoreData.notificationsEvents.length;
    },
    [fetchMore, data?.notificationsEvents?.length],
  );

  return {
    events: data?.notificationsEvents ?? [],
    lastEvents: data?.notificationsEvents?.slice(0, 20) ?? [],
    fetchMoreEvents,
    hasMoreEvents,
    notReadCount: data?.notificationsEvents?.filter(event => !event.read).length ?? 0,
    loading,
    error,
  };
};

export const useNotificationsMessages = (props?: HookProps) => {
  const { lang } = useTranslate();
  const { data, loading, error } = useNotificationsMessagesQuery({
    onError: error => props?.onError?.(extractError(error)),
    variables: {
      offset: 0,
      locale: [lang as Locale],
    },
  });

  return {
    messages: data?.notificationsMessages ?? [],
    lastMessages: data?.notificationsMessages?.slice(0, 20) ?? [],
    notReadCount: data?.notificationsMessages?.filter(event => !event.read).length ?? 0,
    loading,
    error,
  };
};

export const useNotificationsReleases = (props?: HookProps) => {
  const { lang } = useTranslate();
  const { data, loading, error } = useNotificationsReleasesQuery({
    onError: error => props?.onError?.(extractError(error)),
    variables: {
      offset: 0,
      locale: [lang as Locale],
    },
  });

  return {
    releases: data?.notificationsReleases ?? [],
    lastReleases: data?.notificationsReleases?.slice(0, 20) ?? [],
    notReadCount: data?.notificationsReleases?.filter(event => !event.read).length ?? 0,
    loading,
    error,
  };
};

export const useNotificationMessageContent = ({
  notificationContentId,
}: { notificationContentId: number }, props?: HookProps) => {
  const { lang } = useTranslate();
  const { data, loading, error } = useNotificationMessageContentQuery({
    onError: error => props?.onError?.(extractError(error)),
    variables: {
      notificationContentId,
      locale: [lang as Locale],
    },
    skip: !notificationContentId,
  });

  const notificationContent = useMemo(
    () => (data?.notification?.at(0)?.content),
    [data?.notification],
  );

  return {
    messageContent: notificationContent?.hygraph ? notificationContent : null,
    loading,
    error,
  };
};

export const useNotificationReleaseContent = ({
  notificationContentId,
}: { notificationContentId: number }, props?: HookProps) => {
  const { lang } = useTranslate();
  const { data, loading, error } = useNotificationReleaseContentQuery({
    onError: error => props?.onError?.(extractError(error)),
    variables: {
      notificationContentId,
      locale: [lang as Locale],
    },
    skip: !notificationContentId,
  });

  const notificationContent = useMemo(
    () => (data?.notification?.at(0)?.content),
    [data?.notification],
  );

  return {
    releaseContent: notificationContent?.hygraph ? notificationContent : null,
    loading,
    error,
  };
};

export const useNotificationsSettings = (props?: HookProps) => {
  const { data, loading, error } = useNotificationsSettingsQuery({
    onError: error => props?.onError?.(extractError(error)),
  });

  const [updateNotificationsSettings] = useNotificationsSettingsUpdateMutation();

  const updateSettings = useCallback(
    async (options: { id: number; releases?: boolean; events?: boolean; messages?: boolean }, props?: HookProps) => {
      const data = await updateNotificationsSettings({
        variables: {
          id: options.id,
          props: {
            releases_enabled: options.releases,
            events_enabled: options.events,
            messages_enabled: options.messages,
          },
        },
        onError: error => props?.onError?.(extractError(error)),
      });
      return data;
    },
    [updateNotificationsSettings],
  );

  return {
    notificationsSettings: data?.notificationsSettings?.[0] ?? null,
    updateSettings,
    loading,
    error,
  };
};

export const useNotificationsNotReadCount = (props?: HookProps) => {
  const { data, loading, error } = useNotificationsNotReadCountQuery({
    onError: error => props?.onError?.(extractError(error)),
  });

  return {
    notReadCount: data?.notificationsNotReadCount.aggregate?.count ?? 0,
    loading,
    error,
  };
};

export const useNotificationsNotReadByType = () => {
  const { data, loading, error } = useNotificationsNotReadCountByTypeQuery();

  return {
    eventsNotRead: data?.notificationsNotReadEvents.aggregate?.count ?? 0,
    messagesNotRead: data?.notificationsNotReadMessages.aggregate?.count ?? 0,
    releasesNotRead: data?.notificationsNotReadReleases.aggregate?.count ?? 0,
    loading,
    error,
  };
};

export const useNotificationsMutations = () => {
  const [markReadMutation, markReadData] = useNotificationsReadMutation();

  const [markAllReadMutation, markAllReadData] = useNotificationsAllReadMutation();

  const markRead = useCallback(
    async ({ ids }: { ids: number[] }, options?: { shouldRefetch: boolean }) => {
      const data = await markReadMutation({
        variables: { ids },
        refetchQueries: options?.shouldRefetch
          ? [NotificationsNotReadCountDocument]
          : [],
      });
      return data;
    },
    [markReadMutation],
  );

  const markAllRead = useCallback(
    async (options?: { shouldRefetch: boolean }) => {
      const data = await markAllReadMutation({
        refetchQueries: options?.shouldRefetch
          ? [
            NotificationsNotReadCountDocument,
            NotificationsNotReadCountByTypeDocument,
            NotificationsEventsDocument,
            NotificationsMessagesDocument,
            NotificationsReleasesDocument,
          ]
          : [],
      });
      return data;
    },
    [markAllReadMutation],
  );

  return {
    markRead,
    markAllRead,
    markReadData,
    markAllReadData,
  };
};
