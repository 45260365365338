import {
  Button, Icon,
  IconButton, Stack, Switch, Text,
  Textarea, TextareaProps, Tooltip,
} from '@lualtek/react-components';
import clsx from 'clsx';
import {
  ChangeEvent, FormEvent, useCallback,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useBoundingclientrect, useMergeRefs } from 'rooks';
import { Except } from 'type-fest';

import { useMobileContext } from '@/context/use-mobile-context';
import { useTranslate } from '@/core/i18n';

import { DeleteChatModal } from '../delete-chat-modal';
import styles from './input.module.css';

export type LumaInputData = {
  input: string;
}

export type LumaInputProps = Partial<Except <TextareaProps, 'onSubmit'>> & {
  maxHeight?: number;
  rows?: TextareaProps['rows'];
  onSubmit?: (data: LumaInputData) => void;
  disabled?: boolean;
  onStop?: () => void;
  loading?: boolean;
  setIsContextActive: (value: boolean) => void;
  isContextActive: boolean;
  deleteChatMessages: () => Promise<void>;
  loadingDeleteChatMessages?: boolean;
}

const MAX_LUMA_INPUT = 8000;

export const LumaInput: FCClass<LumaInputProps> = ({
  className,
  maxHeight = 265,
  disabled,
  onInput,
  onSubmit,
  onStop,
  loading,
  setIsContextActive,
  isContextActive,
  deleteChatMessages,
  loadingDeleteChatMessages,
  ...otherProps
}) => {
  const { t } = useTranslate();
  const { isMobile } = useMobileContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const rect = useBoundingclientrect(textareaRef);
  const {
    register,
    reset,
    handleSubmit: handleSubmitForm,
  } = useForm<LumaInputData>();
  const { ref, ...registerRest } = register('input', { required: true });
  const refs = useMergeRefs(textareaRef, ref);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleInput = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onInput?.(e);
    if (textareaRef.current) {
      // Reset height
      textareaRef.current.style.height = 'auto';
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      // Set dynamic height
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [onInput, maxHeight]);

  const handleSubmit = useCallback((data: LumaInputData) => {
    onSubmit?.(data);
    reset();
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  }, [onSubmit, reset]);

  const onOpenDeleteModal = useCallback(() => {
    onStop?.();
    setIsModalOpened(true);
  },
  [setIsModalOpened, onStop]);

  return (
    <Stack rowGap={4} fill={false}>
      <Stack
        as="form"
        fill={false}
        className={clsx(styles.Input, className)}
        onSubmit={handleSubmitForm(handleSubmit)}
        onKeyDown={(e) => {
          if (!loading && e.key === 'Enter' && !e.shiftKey && !disabled) {
            e.preventDefault();
            void handleSubmitForm(handleSubmit)(e as FormEvent);
          }
        }}
      >
        <Textarea
          {...otherProps}
          label=""
          aria-label={t('luma:input.label')}
          placeholder={t('luma:input.label')}
          ref={refs}
          onInput={handleInput}
          maxLength={MAX_LUMA_INPUT}
          style={{
            height: rect ? rect?.height : 'auto',
          }}
          {...registerRest}
        />
        <Stack direction="row" columnGap={8} fill={false} className={styles.SendButton}>
          {disabled && <IconButton icon="remove" kind="secondary" sentiment="warning" onClick={onStop} />}
          {!disabled && (
            <IconButton
              type="submit"
              icon="arrow-right"
              aria-label={t('luma:input.sendLabel')}
              disabled={disabled}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" fill={false} hAlign="space-between" hPadding={8}>
        <Button
          dimension="small"
          kind="secondary"
          onClick={onOpenDeleteModal}
        >
          {t('luma:input.newChat')}
        </Button>
        <Stack direction="row" vAlign="center" fill={false} columnGap={4}>
          <Switch
            defaultChecked={isContextActive}
            dimension="small"
            label={t('luma:input.useContext')}
            onClick={() => setIsContextActive(!isContextActive)}
          />
          {!isMobile && (
            <Tooltip usePortal trigger={<Icon source="c-question" dimension={16} />}>
              <Text as="p" maxWidth="30ch" size={14}>
                {t('luma:input.useContextTooltip')}
              </Text>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <DeleteChatModal
        isOpen={isModalOpened}
        onDismiss={() => setIsModalOpened(false)}
        loading={loadingDeleteChatMessages}
        onDelete={deleteChatMessages}
      />
    </Stack>
  );
};

LumaInput.displayName = 'LumaInput';
