import { InlineToast } from '@lualtek/react-components';

import { useTranslate } from '@/core/i18n';

export const LumaNoTokens: FCClass = ({
  className,
  ...otherProps
}) => {
  const { t } = useTranslate();

  return (
    <InlineToast {...otherProps} className={className} icon="c-info" kind="info">
      {t('luma:messages.noToken')}
    </InlineToast>
  );
};

LumaNoTokens.displayName = 'LumaNoTokens';
