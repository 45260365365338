import {
  Datetime, Icon,
  Prose, Spinner, Stack, Text, Title,
} from '@lualtek/react-components';
import { FC, Ref, useMemo } from 'react';
import Markdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { CodeBlock } from '@/components/common/shared/code-block';
import { useTranslate } from '@/core/i18n';

import styles from './balloon.module.css';

export type LumaBalloonProps = React.ComponentPropsWithRef<'li'> & {
  isResponse?: boolean;
  children: string;
  isLoading?: boolean;
  datetime?: string;
}

const markdownComponents: Components = {
  p({ ref, ...props }) {
    return <Text {...props} ref={ref as Ref<HTMLParagraphElement>} as="p" size={16} />;
  },
  h1({ ref, ...props }) {
    return <Title {...props} ref={ref as Ref<HTMLHeadingElement>} level="5" as="h1" />;
  },
  h2({ ref, ...props }) {
    return <Title {...props} ref={ref as Ref<HTMLHeadingElement>} level="5" as="h2" />;
  },
  h3({ ref, ...props }) {
    return <Title {...props} ref={ref as Ref<HTMLHeadingElement>} level="5" as="h3" />;
  },
  h4({ ref, ...props }) {
    return <Title {...props} ref={ref as Ref<HTMLHeadingElement>} level="5" as="h4" />;
  },
  h5({ ref, ...props }) {
    return <Title {...props} ref={ref as Ref<HTMLHeadingElement>} level="5" as="h5" />;
  },
  h6({ ref, ...props }) {
    return <Title {...props} ref={ref as Ref<HTMLHeadingElement>} level="6" as="h6" />;
  },
  ul({ ref, ...props }) {
    return <Text {...props} ref={ref as Ref<HTMLUListElement>} as="ul" size={16} />;
  },
  table(props) {
    return (
      <div className={styles.TableWrapper}>
        <table data-table-separators {...props} />
      </div>
    );
  },
  code(props) {
    const { children: code, className } = props;
    return <CodeBlock code={code} className={className} />;
  },
};

export const LumaBalloon: FC<LumaBalloonProps> = ({
  className,
  children,
  isResponse,
  isLoading,
  datetime,
  ...otherProps
}) => {
  const { langCanonical } = useTranslate();

  const isNotToday = useMemo(() => {
    const date = new Date(datetime ?? '');
    const today = new Date();
    return date.getDate() !== today.getDate()
    || date.getMonth() !== today.getMonth()
    || date.getFullYear() !== today.getFullYear();
  }, [datetime]);
  const isNow = useMemo(() => new Date().getTime() - new Date(datetime ?? '').getTime() < 2 * 60 * 1000, [datetime]);

  return (
    <Stack
      as="li"
      direction="row"
      vPadding={8}
      columnGap={8}
      vAlign="start"
      hPadding={isResponse ? undefined : 16}
      data-chat-balloon-response={isResponse}
      className={styles.Balloon}
      {...otherProps}
    >
      {!isResponse && datetime && (
        <Text className={styles.Datetime} size={12} dimmed={5}>
          {isNow ? 'now' : (
            <Datetime
              date={datetime}
              locale={langCanonical}
              options={{
                year: undefined,
                month: isNotToday ? 'short' : undefined,
                weekday: isNotToday ? 'short' : undefined,
                day: isNotToday ? 'numeric' : undefined,
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
              }}
            />
          )}
        </Text>
      )}
      {isResponse && <Icon source="ai-chat" data-shrink="false" dimension={18} fill="url(#DawnGradient)" />}
      <Prose as={Stack} gap="small" className={styles.MessageWrapper}>
        {(isLoading && isResponse) ? <Spinner /> : (
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={markdownComponents}
          >
            {children}
          </Markdown>
        )}
      </Prose>
    </Stack>
  );
};

LumaBalloon.displayName = 'LumaBalloon';
