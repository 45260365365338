
import { useCallback, useMemo } from 'react';

import { getJWTPayload } from '@/core/auth/jwt';
import {
  useAddTelegramUserMutation,
  useAddUserDeviceNotificationEventTypesMutation,
  useDeleteUserDeviceNotificationsMutation,
  useDeviceNotificationsEventTypesQuery,
  UserDataFragment,
  useUpdateTelegramUserMutation,
  useUpdateTelegramUserStatusMutation,
  useUserDeviceNotificationQuery,
  useUserDevicesNotificationsQuery,
  useUserQuery,
} from '@/generated/graphql';
import { extractError } from '@/services/apollo/extract-error';

import { HookProps } from '../types';

export type UserMapped = {
  id: number;
  blocked?: UserDataFragment['blocked'];
  email: UserDataFragment['email'];
  avatarUrl: string;
  organizations: UserDataFragment['organizations'];
  telegram?: UserDataFragment['telegramUsers'][0];
}

export const useUser = () => {
  const jwtPayload = getJWTPayload();
  const { data, loading, error } = useUserQuery({
    variables: {
      id: jwtPayload?.userId ?? 0,
    },
    nextFetchPolicy: 'cache-first',
  });

  const user: UserMapped | null = useMemo(() => {
    if (!data?.user) {
      return null;
    }

    const { telegramUsers, avatarUrl, ...rest } = data.user;

    return {
      ...rest,
      telegram: telegramUsers?.[0],
      avatarUrl: avatarUrl ?? 'https://ik.imagekit.io/lualtek/tr:f-webp/console/avatars/random/leaf-4',
    };
  }, [data?.user]);

  return {
    user,
    loading,
    error,
  };
};

export const useUserDeviceNotifications = ({ deviceId }: { deviceId: number }) => {
  const { user } = useUser();
  const {
    data, loading, error, refetch,
  } = useUserDeviceNotificationQuery({
    variables: {
      userId: user?.id ?? 0,
      deviceId,
    },
  });

  return {
    userDeviceNotifications: data?.userDeviceNotification ?? [],
    hasNotificationsEnabled: Number(data?.userDeviceNotification?.length) > 0,
    refetch,
    loading,
    error,
  };
};

export const useUserDevicesNotifications = () => {
  const { user } = useUser();
  const {
    data, loading, error, refetch,
  } = useUserDevicesNotificationsQuery({
    variables: {
      userId: user?.id ?? 0,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    userDeviceNotifications: data?.userDeviceNotifications ?? [],
    loading,
    error,
    refetch,
  };
};

export const useUserDeviceNotificationEventTypeMutation = (props?: HookProps) => {
  const { user } = useUser();
  const { data } = useDeviceNotificationsEventTypesQuery();
  const [
    addUserDeviceNotificationMutation,
    { loading, error },
  ] = useAddUserDeviceNotificationEventTypesMutation({
    onError: error => props?.onError?.(extractError(error)),
  });
  const [
    deleteUserDeviceNotificationsMutation,
    { loading: isDeleteLoading, error: deleteError },
  ] = useDeleteUserDeviceNotificationsMutation({
    onError: error => props?.onError?.(extractError(error)),
  });

  const enableUserDeviceNotifications = useCallback(async (deviceId: number) => {
    await addUserDeviceNotificationMutation({
      variables: {
        userId: user?.id ?? 0,
        deviceId,
        notificationEventTypesInput: data?.deviceEventTypes?.map(type => ({
          notification_event_type_id: type.id,
        })) ?? [],
      },
    });
  }, [addUserDeviceNotificationMutation, data?.deviceEventTypes, user?.id]);

  const disableUserDeviceNotifications = useCallback(async (deviceId: number) => {
    await deleteUserDeviceNotificationsMutation({
      variables: {
        userId: user?.id ?? 0,
        deviceId,
      },
    });
  }, [deleteUserDeviceNotificationsMutation, user?.id]);

  const currentError = useMemo(() => {
    if (error) {
      return extractError(error);
    }

    if (deleteError) {
      return extractError(deleteError);
    }

    return undefined;
  }, [deleteError, error]);

  return {
    enableUserDeviceNotifications,
    disableUserDeviceNotifications,
    loading: loading || isDeleteLoading,
    error: currentError,
  };
};

export const useUserTelegramMutation = (props?: HookProps) => {
  const [addTelegramUser, addTelegramUserData] = useAddTelegramUserMutation({
    onError: error => props?.onError?.(extractError(error)),
  });

  const [updateTelegramUser, updateTelegramUserData] = useUpdateTelegramUserMutation({
    onError: error => props?.onError?.(extractError(error)),
  });

  const [updateTelegramUserStatus, updateTelegramUserStatusData] = useUpdateTelegramUserStatusMutation({
    onError: error => props?.onError?.(extractError(error)),
  });

  return {
    addTelegramUser,
    addTelegramUserData,
    updateTelegramUser,
    updateTelegramUserData,
    updateTelegramUserStatus,
    updateTelegramUserStatusData,
  };
};
