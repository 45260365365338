import { useEffect, useState } from 'react';

const SCROLL_DOWN_BUTTON_SCROLL_THRESHOLD = 450;
const LOAD_MORE_SCROLL_THRESHOLD = 2;

/**
 * Custom hook to listen to the scroll event of a specific element.
 *
 * @param {React.RefObject<HTMLElement>} elementRef - The ref of the element to attach the scroll event.
 * @param {Function} callback - The callback function to be called on scroll.
 */
export const useLumaScroller = (
  elementRef: React.RefObject<HTMLElement>,
  callback: (status?: boolean) => void,
  { kind }: { kind: 'jumptobottom' | 'fetchmore' },
) => {
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return () => null;
    }

    const handleScroll = () => {
      setLastScrollPosition(element.scrollTop);

      const isJumpToBottom = (
        element.scrollTop + element.clientHeight < element.scrollHeight - SCROLL_DOWN_BUTTON_SCROLL_THRESHOLD
      );

      const isFetchMore = element.scrollTop < LOAD_MORE_SCROLL_THRESHOLD;

      // Going down
      if (kind === 'fetchmore' && lastScrollPosition < element.scrollTop) {
        return;
      }

      if (kind === 'jumptobottom') {
        callback(isJumpToBottom);
      }

      if (isFetchMore && kind === 'fetchmore') {
        callback();
      }
    };

    element.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef, callback, lastScrollPosition, kind]);
};
