import { Stack } from '@lualtek/react-components';

import { useFeatureLimits } from '@/api/organization';
import { NavLink } from '@/components/common/mobile/navlink';
import { LumaWrapper } from '@/components/luma/mobile/wrapper';
import { testIds } from '@/core/helpers/test-ids';
import { useNavigation } from '@/core/navigation/use-navigation';

type NavigationProps = PropsWithClass & {
  collapsed?: boolean;
}

export const Navigation = ({
  collapsed,
  ...otherProps
}: NavigationProps) => {
  const { mainMenu } = useNavigation();
  const { featureLimitsParsed } = useFeatureLimits();

  return (
    <Stack
      as="nav"
      direction="row"
      vAlign="center"
      data-navigation-collapsed={collapsed}
      {...otherProps}
      data-testid={testIds.shell.mainMenu}
    >
      {mainMenu.map(item => (
        <NavLink
          key={item.label}
          href={item.href}
          // label={item.label}
          icon={item.icon}
          active={item.active}
          data-testid={`${testIds.shell.mainMenuButton}-${item.key}`}
        />
      ))}
      {featureLimitsParsed.chatDisabled ? null : <LumaWrapper />}
    </Stack>
  );
};
