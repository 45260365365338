import { RichText, RichTextProps } from '@graphcms/rich-text-react-renderer';
import { PolyRefComponent, Text, TitleProps } from '@lualtek/react-components';
import { forwardRef } from 'react';

export type CmsTextProps = TitleProps & {
  content?: RichTextProps['content'] | null;
}

export const CmsText = forwardRef(({
  content,
  ...otherProps
}, ref) => (content ? (
  <RichText
    content={content}
    renderers={{
      p: ({ children }) => <Text as="p" ref={ref} {...otherProps}>{children}</Text>,
    }}
  />
) : null)) as PolyRefComponent<typeof Text, CmsTextProps>;
