import {
  Avatar,
  Button, Datetime, Drawer, DrawerProps, IconButton, Prose, Separator, Stack, Text, Title, useOverlayContext,
} from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { useNotificationMessageContent } from '@/api/notifications';
import { useMobileContext } from '@/context/use-mobile-context';
import { useTranslate } from '@/core/i18n';

import { CmsContent } from '../cms-text';

export const MessageDrawer: FC<DrawerProps> = ({ isOpen, onClose, ...otherProps }) => {
  const router = useRouter();
  const { headingId } = useOverlayContext();
  const { langCanonical, t } = useTranslate();
  const { isMobile } = useMobileContext();
  const { messageContent } = useNotificationMessageContent({
    notificationContentId: Number(router.query.notificationContentId),
  });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} showHeader={false} maxWidth="630px" {...otherProps}>
      <Stack hPadding={isMobile ? 24 : 40} vPadding={32} rowGap={32} hAlign="start">
        <Stack rowGap={32}>
          <Stack columnGap={32} direction="row" hAlign="space-between">
            <Title as="h3" level="3" id={headingId} lineHeight="small">
              {messageContent?.hygraph?.message.title}
            </Title>
            <IconButton icon="remove" kind="secondary" title={t('common:glossary.close')} onClick={onClose} />
          </Stack>
          <Separator />
          <Stack direction="row" hAlign="start" vAlign="center" columnGap={8} fill={false}>
            <Avatar src={messageContent?.hygraph?.author?.avatar.url} />
            <Stack>
              <Text as="strong" lineHeight="small">{messageContent?.hygraph?.author?.name}</Text>
              {messageContent?.hygraph?.publishedAt && (
                <Text as="span" lineHeight="small" size={14} dimmed={5}>
                  <Datetime date={messageContent.hygraph.publishedAt} locale={langCanonical} />
                </Text>
              )}
            </Stack>
          </Stack>
          <Separator />
        </Stack>
        <Prose>
          <CmsContent content={messageContent?.hygraph?.message.content.raw} />
        </Prose>
        <Button onClick={onClose} dimension="big">{t('common:glossary.close')}</Button>
      </Stack>
    </Drawer>
  );
};
