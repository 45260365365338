import {
  ClampText,
  Container, IconButton, Panel, Stack, Title,
} from '@lualtek/react-components';
import dynamic from 'next/dynamic';

import { ThemeSwitcherProps } from '@/components/common/desktop/theme-switcher/theme-switcher';
import { LangSwitcher } from '@/components/common/shared/lang-switcher';
import { BaseLayout } from '@/components/layouts/shared/base-layout';
import { ColumnLayoutType } from '@/components/layouts/types';
import { useHistoryContext } from '@/context/use-history-context';

import styles from './column-layout.module.css';

const DynThemeSwitcher = dynamic<ThemeSwitcherProps>(
  async () => import('@/components/common/mobile/theme-switcher').then(mod => mod.ThemeSwitcher),
  { ssr: false },
);

export const ColumnLayout: ColumnLayoutType = ({
  className,
  children,
  width = 'small',
  title,
  ...otherProps
}) => {
  const { goBack } = useHistoryContext();

  return (
    <BaseLayout>
      <main className={className} {...otherProps}>
        <Panel as="header" vibrant className={styles.Header}>
          <Stack
            vAlign="center"
            fill={false}
            rowGap={24}
            hPadding={16}
          >
            <Stack direction="row" columnGap={32} fill={false} hAlign="space-between" vAlign="center">
              <div className={styles.HeaderSection}>
                <IconButton
                  icon="ctrl-left"
                  kind="flat"
                  onClick={goBack}
                />
              </div>

              {title && <Title level="5" as="h1"><ClampText rows={1}>{title}</ClampText></Title>}

              <Stack
                className={styles.HeaderSection}
                direction="row"
                rowGap={4}
                vAlign="center"
                hAlign="end"
                fill={false}
                inline
              >
                <LangSwitcher />
                <DynThemeSwitcher dimension="regular" kind="flat" />
              </Stack>
            </Stack>
          </Stack>
        </Panel>
        <Container dimension={width} className={styles.Container}>
          {children}
        </Container>
      </main>
    </BaseLayout>
  );
};
