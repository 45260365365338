import { RichText, RichTextProps } from '@graphcms/rich-text-react-renderer';
import {
  PolyRefComponent, Text, Title, TitleProps,
} from '@lualtek/react-components';
import { forwardRef } from 'react';

export type CmsTitleProps = TitleProps & {
  content: RichTextProps['content'];
}

export const CmsTitle = forwardRef(({
  content,
  as,
  ...otherProps
}, ref) => (
  <RichText
    content={content}
    renderers={{
      bold: ({ children }) => <Text weight="bold" as="strong">{children}</Text>,
      p: ({ children }) => <Title as="p" ref={ref} {...otherProps}>{children}</Title>,
    }}
  />
)) as PolyRefComponent<typeof Title, CmsTitleProps>;
