import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useCurrentQuery = () => {
  const router = useRouter();

  const getFullQuery = useCallback(
    () => {
      const pathNoQuery = router.asPath.split('?').at(0);
      const totalQuery: Record<string, string> = {
        companyID: router.query.companyID as string,
        ssologin: router.query.ssologin as string,
        redirect: router.query.redirect as string,
        next: router.query.next as string ?? (router.pathname === '/logout' ? '/' : pathNoQuery),
      };

      const stringQuery = Object.keys(totalQuery)
        .filter(key => totalQuery[key])
        .map(key => `${key}=${encodeURIComponent(totalQuery[key])}`)
        .join('&');

      const stringQueryNoNext = Object.keys(totalQuery)
        .filter(key => totalQuery[key])
        .filter(key => key !== 'next')
        .map(key => `${key}=${encodeURIComponent(totalQuery[key])}`)
        .join('&');

      return {
        query: totalQuery,
        stringQuery,
        stringQueryNoNext,
      };
    },
    [router.query, router.pathname, router.asPath],
  );

  // All possible query params
  return {
    analysisslug: router.query.analysisslug as string,
    analysisid: Number(router.query.analysisid as string),
    analysisactiveid: Number(router.query.analysisactiveid as string),
    appid: Number(router.query.appid as string),
    deviceid: Number(router.query.deviceid as string),
    automationId: Number(router.query.automationid as string),
    areaid: Number(router.query.areaid as string),
    next: router.query.next as string || '/',
    gotoscene: Number(router.query.gotoscene as string) || undefined,
    sslogin: router.query.ssologin as string,
    companyid: router.query.companyID as string,
    redirect: router.query.redirect as string,
    getFullQuery,
  };
};
