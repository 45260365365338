import {
  ClampText,
  Container, ContainerProps, IconButton, Stack, StackProps, Title,
} from '@lualtek/react-components';
import { m } from 'motion/react';
import dynamic from 'next/dynamic';
import { useMediaMatch } from 'rooks';

import { ThemeSwitcherProps } from '@/components/common/desktop/theme-switcher/theme-switcher';
import { LangSwitcher } from '@/components/common/shared/lang-switcher';
import { BaseLayout } from '@/components/layouts/shared/base-layout';
import { useHistoryContext } from '@/context/use-history-context';

import { ColumnLayoutType } from '../../types';
import styles from './column-layout.module.css';

const DynThemeSwitcher = dynamic<ThemeSwitcherProps>(
  async () => import('@/components/common/desktop/theme-switcher').then(mod => mod.ThemeSwitcher),
  { ssr: false },
);

export type ColumnLayoutProps = {
  showLanguageSwitcher?: boolean;
  width?: ContainerProps['dimension'];
  vAlign?: StackProps['vAlign'];
  title?: string;
}

export const ColumnLayout: ColumnLayoutType = ({
  className,
  children,
  width = 'small',
  showLanguageSwitcher = true,
  title,
  ...otherProps
}) => {
  const isLargeViewport = useMediaMatch('(min-width: 60em)');
  const { goBack } = useHistoryContext();

  return (
    <BaseLayout>
      <main className={className} {...otherProps}>
        <Container dimension={width} className={styles.Container}>
          <m.div
            className={styles.Shape}
            initial={{ x: -200, rotate: 30, opacity: 0 }}
            animate={{ x: 0, rotate: 60, opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 30,
            }}
          />
          <Stack
            className={styles.Header}
            direction="row"
            columnGap={32}
            vPadding={32}
            fill={false}
            hAlign="space-between"
          >
            <Stack direction="row" columnGap={24} vAlign="center" inline>
              <IconButton
                aria-label="Go to previous page"
                onClick={goBack}
                kind="secondary"
                dimension={isLargeViewport ? 'big' : 'regular'}
                icon="arrow-left"
              />
              {title && <Title as="h1" level="4"><ClampText rows={1}>{title}</ClampText></Title>}
            </Stack>

            <Stack direction="row" columnGap={16} vAlign="center" inline>
              {showLanguageSwitcher && <LangSwitcher />}
              <DynThemeSwitcher dimension={isLargeViewport ? 'big' : 'regular'} />
            </Stack>
          </Stack>
          {children}
        </Container>
      </main>
    </BaseLayout>
  );
};
