import {
  Panel,
  Sheet, SheetProps, useResponsiveContext,
} from '@lualtek/react-components';

import { Luma } from '@/components/luma/desktop/chat';
import {
  LumaLogo,
} from '@/components/luma/shared';
import { useLumaContext } from '@/context/use-luma-context';

import { useLumaWrapperLogic } from '../../shared/use-luma-wrapper-logic';
import styles from './luma-wrapper.module.css';

export type LumaWrapperProps = {
  trigger?: SheetProps['trigger'];
}
export const LumaWrapper: FCChildren<LumaWrapperProps> = ({
  trigger,
}) => {
  const { matches } = useResponsiveContext();
  const { isLumaOpened, setIsLumaOpened } = useLumaContext();
  const { lumaProps } = useLumaWrapperLogic();

  if (isLumaOpened && matches.extraLarge) {
    return (
      <Panel
        bordered
        radius={24}
        vibrant
        showGlow
        glowSpread={200}
        rainbowColors
        className={styles.ChatPanel}
      >
        <Luma {...lumaProps} />
      </Panel>
    );
  }

  return (
    <Sheet
      open={isLumaOpened}
      onOpenChange={open => setIsLumaOpened(open)}
      direction="left"
      heading={<LumaLogo dimension="big" />}
      maxWidth={matches.small ? '700px' : '85vw'}
      trigger={trigger}
      noPadding
      showHeading={false}
    >
      <div className={styles.ChatPanel}>
        <Luma {...lumaProps} />
      </div>
    </Sheet>
  );
};

