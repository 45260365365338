import {
  addHours,
  format, formatDistanceToNow, isValid, Locale,
  parseISO, subDays,
} from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import { it } from 'date-fns/locale/it';
import { useRouter } from 'next/router';

const LOCALES: { en: Locale; it: Locale } = { en: enGB, it };

const parseDate = (date: string | Date) => (typeof date === 'object' ? date : parseISO(date));

const dayMonthDescriptive = (locale: Locale) => (date: string | Date) => (
  format(parseDate(date), 'EEEE d LLLL', { locale })
);
const monthDayHour = (locale: Locale) => (date: string | Date) => format(
  parseDate(date), 'HH:mm — dd MMMM', { locale },
);

const weekDayHour = (locale: Locale) => (date: string | Date) => format(
  parseDate(date), 'EEEE HH:mm', { locale },
);
const today = (locale: Locale) => () => format(parseDate(new Date()), 'yyyy-MM-dd', { locale });
const yesterday = (locale: Locale) => () => format(parseDate(new Date()), 'yyyy-MM-dd', { locale });

const todayPlus1HourISO = () => addHours(new Date(), 1).toISOString();

const daysBackDateISO = (daysBack: number) => (
  subDays(parseDate(new Date()), daysBack).toISOString()
);

const distanceToNow = (locale: Locale) => (date: string | Date) => (
  formatDistanceToNow(parseDate(date), { includeSeconds: true, locale })
);

const formatYearMonthDay = (locale: Locale) => (date: string | Date) => (
  format(parseDate(date), 'yyyy-MM-dd', { locale })
);
const formatYearMonthDaySlash = (locale: Locale) => (date: string | Date) => format(parseDate(date), 'P', { locale });

/**
 * Helpers hook to parse and format date and time.
 */
export const useDateTime = () => {
  const router = useRouter();
  const locale = router.locale as 'en' | 'it';
  return ({
    dayMonthDescriptive: dayMonthDescriptive(LOCALES[locale]),
    monthDayHour: monthDayHour(LOCALES[locale]),
    weekDayHour: weekDayHour(LOCALES[locale]),
    yesterday: yesterday(LOCALES[locale]),
    today: today(LOCALES[locale]),
    todayPlus1HourISO,
    daysBackDateISO,
    distanceToNow: distanceToNow(LOCALES[locale]),
    formatYearMonthDay: formatYearMonthDay(LOCALES[locale]),
    formatYearMonthDaySlash: formatYearMonthDaySlash(LOCALES[locale]),
    isDate: (date: string | Date) => {
      try {
        return isValid(parseDate(date));
      } catch {
        return false;
      }
    },
  });
};
