import { IconNames } from '@lualtek/icons';
import {
  Badge,
  BlankButton, Icon, Stack, StackProps,
} from '@lualtek/react-components';
import { forwardRef, Ref, useCallback } from 'react';

import { Link } from '@/core/i18n';

import styles from './navlink.module.css';

type NavLinkProps = {
  href?: string;
  onClick?: () => void;
  // label: string;
  icon: IconNames;
  active?: boolean;
  showBadge?: boolean;
}

const commonProps: Partial<StackProps> = {
  vAlign: 'center',
  hAlign: 'center',
};

export const NavLink = forwardRef<HTMLElement, NavLinkProps>(({
  href,
  // label,
  onClick,
  showBadge,
  icon,
  active,
  ...otherProps
}, forwardedRef) => {
  const LinkContent = useCallback(() => (
    <Badge showBadge={showBadge} badgeSize={8} gap={4}>
      <Icon className={styles.Decorator} source={icon} dimension={24} />
      {/* <Text as="span" size={12} responsive={false}>{label}</Text> */}
    </Badge>
  ), [icon, showBadge]);

  return href ? (
    <Stack
      as={Link}
      href={href}
      ref={forwardedRef as Ref<HTMLAnchorElement>}
      className={styles.Link}
      aria-current={active ? 'page' : undefined}
      {...commonProps}
      {...otherProps}
    >
      <LinkContent />
    </Stack>
  ) : (
    <Stack
      as={BlankButton}
      ref={forwardedRef as Ref<HTMLButtonElement>}
      className={styles.Link}
      onClick={onClick}
      {...commonProps}
      {...otherProps}
    >
      <LinkContent />
    </Stack>
  );
});
