export const STORAGE_KEY_LOCAL_APP = 'lualtek-local-app';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const STORAGE_KEY_LOCAL_DASHBOARD_DAYSBACK_deprecated = 'lualtek-local-dashboard-daysback';

export const STORAGE_KEY_LOCAL_LANG = 'lualtek-local-lang';

export const STORAGE_KEY_LOCAL_DEVICES_VIEW = 'lualtek-local-devices-view';

export const STORAGE_KEY_LOCAL_FAVOURITE_DEVICES_VIEW = 'lualtek-local-favourite-devices-view';

export const STORAGE_KEY_LOCAL_AREA_DETAILS_SHOW = 'lualtek-local-area-details-show';

export const STORAGE_KEY_EXPIRATION_OTP = 'lualtek-expiration-otp';

export const STORAGE_KEY_JWT_OTP = 'lualtek-jwt-otp';

export const STORAGE_KEY_LUMA = 'lualtek-luma';

export const STORAGE_HISTORY_LUMA = 'lualtek-luma-history';
